<template>
   <div class="col-12">
      <div class="card mb-1">
         <div class="card-body p-3">
            <div class="row align-items-center font-12">
               <div class="col-md cursor-pointer" @click="view">
                  <h1 class="weight-600 font-16 limitador m-b-10 text-center text-md-left">{{ order.data == null ? $t('ecommerceSales.unknown') : String(order.data.dayOfMonth).padStart(2, '0') +'/'+ String(order.data.monthValue).padStart(2, '0') +'/'+ order.data.year +' '+ String(order.data.hour).padStart(2, '0') +':'+ String(order.data.minute).padStart(2, '0') }}</h1>
                  <div class="row text-center text-md-left">
                     <div class="col-6">
                        <span class="limitador">
                           <i class="fab fa-discord color-theme font-10 mr-1"></i>
                           <strong class="text-uppercase font-11 text-secondary d-none d-lg-inline mr-1">{{ $t('ecommerceSales.clientDiscord') }}: </strong>
                           <span>{{ order.nomeCompleto == null ? $t('ecommerceSales.unknown') : order.nomeCompleto }}</span>
                        </span>
                     </div>
                     <div class="col-6">
                        <span class="limitador">
                           <a :href="order.urlPagamento" target="_blank">
                              <i class="fal fa-credit-card color-theme font-10 mr-1"></i>
                              <strong class="text-uppercase font-11 text-secondary d-none d-lg-inline mr-1">{{ $t('ecommerceSales.paymentMethod') }}: </strong>
                              <span>{{ order.pagamento == null ? $t('ecommerceSales.unknown') : order.pagamento }}</span>
                              <i class="far fa-external-link-alt font-10 ml-2" v-if="order.urlPagamento"></i>
                           </a>
                        </span>
                     </div>
                  </div>
               </div>
               <div class="col col-md-3 text-center mt-2 mt-md-0" v-if="edit">
                  <a href="javascript:;" @click="changeStatus">
                     <i class="far fa-sync color-theme font-14 d-none d-md-block mb-1"></i>
                     <span class="font-14">{{ order.status == null ? $t('ecommerceSales.unknown') : order.status }}</span>
                  </a>
               </div>
               <div class="col col-md-3 text-center mt-2 mt-md-0 cursor-pointer" @click="view">
                  <span class="font-16"><small class="color-theme">R$ </small>{{ order.valor == null ? $t('ecommerceSales.unknown') : parseFloat(order.valor).toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}</span>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

import Swal from 'sweetalert2'
import { mapState } from 'vuex'

export default {
   name: 'Order',
   props: ['order', 'index', 'edit'],
   computed: {
      ... mapState({
         urlRest: state => state.urlRest
      })
   },
   methods: {
      view : function () {
         this.$emit('view', this.order)
      },
      changeStatus : function () {
         Swal.fire({
            icon: 'info',
            title: 'Status',
            input: 'select',
            inputOptions: this.$t('ecommerceSales.status').reduce((acc, item) => {
               if (item.key != "Todos") {
                  acc[item.key] = item.value;
               }
               return acc;
            }, {}),
            inputValue: this.order.status,
            confirmButtonText: this.$t("serverSettings.swal.btnConfirm"),
            showCancelButton: true,
            cancelButtonText: this.$t("serverSettings.swal.btnCancel"),
            inputValidator: (value) => {
               if (!value) {
                  return this.$t('ecommerceSales.invalidStatus')
               }
            }
         }).then((result) => {
            if (result.isConfirmed) {
               this.$store.dispatch('alternarTelaCarregamento', true)

               this.$axios({
                  method: 'post',
                  url: this.urlRest +'servicos/changeStatusOrderEcommerce',
                  headers: {
                     'Content-Type': 'application/json'
                  },
                  data: {"id": this.order.id, "status": result.value}

               }).then(() => {
                  this.order.status = result.value

                  this.$toast.fire({
                     icon: 'success',
                     title: this.$t('ecommerceSales.savedStatus')
                  })
               }).catch((error) => {
                  if (error.response != undefined) {
                     if (error.response.status == 408) {
                        this.$store.dispatch('deslogar')

                        this.$toast.fire({
                           icon: 'warning',
                           title: this.$t("serverSettings.swal.expired")
                        });
                     } else {
                        this.$toast.fire({
                           icon: 'error',
                           title: 'Erro: ' + error.response.status
                        })
                     }
                  } else {
                     this.$toast.fire({
                        icon: 'error',
                        title: error
                     })
                  }
               }).finally(() => {
                  this.$store.dispatch('alternarTelaCarregamento', false)
               })
            }
         });
      }
   }
}

</script>