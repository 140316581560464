<template>
   <div class="row">
      <div id="search-nav" class="col-12 d-block btn-group dropup">
         <button type="button" class="btn btn-primary dropdown-toggle d-block d-lg-none" :class="search.result.length > 0 ? '' : 'empty'" data-toggle="dropdown" aria-expanded="false">
            <i class="far fa-search font-17"></i>
         </button>
         <div class="card mb-2 dropdown-menu dropdown-menu-left">
            <div class="card-body pb-1">
               <div class="row m-0">
                  <div class="col-12 col-sm-6 col-md-3 m-b-10 px-1">
                     <label class="mb-1"><i class="far fa-sync font-12 color-theme mr-1"></i> Status</label>
                     <v-select class="rounded" title="Desktop" :options="$t('ecommerceSales.status')" label="value" :reduce="s => s.key" v-model="search.status" @input="search.result = []" :disabled="search.groupByStatus" />
                     <select title="Mobile" class="form-control" v-model="search.status" @change="search.result = []" :disabled="search.groupByStatus">
                        <option v-for="(option, index) in $t('ecommerceSales.status')" :key="index" :value="option.key">{{ option.value }}</option>
                     </select>
                  </div>
                  <div class="col-12 col-sm-6 col-md-4 m-b-10 px-1">
                     <label class="mb-1"><i class="fab fa-discord font-12 color-theme mr-1"></i> {{ $t('ecommerceSales.clientDiscord') }}</label>
                     <input type="text" class="form-control rounded" v-model="search.clientDiscord" maxlength="200" @keyup.enter="searchSales" />
                  </div>
                  <div class="col-12 col-sm-8 col-md-4 px-0">
                     <div class="row m-0">
                        <div class="col-6 m-b-10 px-1">
                           <label class="mb-1"><i class="far fa-calendar color-theme font-12 mr-1"></i> {{ $t("ecommerceSales.initialDate") }}</label>
                           <datePicker class="rounded" title="Desktop" :format="'dd/MM/yyyy'" :language="ptBR" v-model="search.initialDate"></datePicker>
                           <input title="Mobile" class="form-control" type="date" v-model="search.initialDate" />
                        </div>
                        <div class="col-6 m-b-10 px-1">
                           <label class="mb-1"><i class="far fa-calendar color-theme font-13 mr-1"></i> {{ $t("ecommerceSales.finalDate") }}</label>
                           <datePicker class="rounded" title="Desktop" :format="'dd/MM/yyyy'" :language="ptBR" v-model="search.finalDate"></datePicker>
                           <input title="Mobile" class="form-control" type="date" v-model="search.finalDate" />
                        </div>
                     </div>
                  </div>
                  <div class="col-12 col-sm-4 col-md-1 m-b-10 px-1 align-self-end">
                     <button type="button" class="btn btn-primary w-100 rounded" @click="searchSales">
                        <i class="fa fa-search font-13"></i>
                     </button>
                  </div>
                  <div class="col-12 px-1 mb-2 d-flex justify-content-end align-items-center">
                     <label class="switch">
                        <input type="checkbox" v-model="search.groupByStatus"><span class="slider"></span>
                     </label>
                     <label class="ml-2 mb-1">{{ $t("ecommerceSales.groupByStatus") }}</label>
                  </div>
               </div>
            </div>
         </div>
      </div>

      <div class="col-12">
         <div class="card" v-if="search.groupByStatus && search.result.length > 0">
            <div class="card-body p-y-10">
               <div class="row">
                  <div class="col-6 pl-3 mt-1 m-b-10 text-center weight-600 text-uppercase font-14 color-theme"><i class="far fa-stream font-12 mr-2"></i>Status</div>
                  <div class="col-6 pr-3 mt-1 m-b-10 text-center weight-600 text-uppercase font-14 color-theme"><i class="far fa-money-bill-wave-alt font-12 mr-2"></i>{{ $t('ecommerceSales.value') }}</div>

                  <div class="col-12">
                     <orderStatus v-for="(status, index) in search.result" :key="index" :status="status" :index="index" />
                  </div>

                  <div class="col-6 pl-3 my-2 text-center weight-600 text-uppercase font-14 color-theme">Total</div>
                  <div class="col-6 pr-3 my-2 text-center weight-600 text-uppercase font-14 color-theme"><small>R$ </small>{{ search.result.reduce((total, p) => total + parseFloat(p.valor), 0).toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}</div>
               </div>
            </div>
			</div>
         <div class="row" v-else-if="!search.groupByStatus">
            <order v-for="(order, index) in search.result" :key="index" :order="order" :index="index" :edit="true" @view="abrirModal($event)" />
			</div>
		</div>

      <!-- modalOrder -->
      <div class="modal fade" id="modalOrder" aria-labelledby="modalOrderLabel" aria-hidden="true">
         <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
               <div class="modal-header">
                  <h5 class="modal-title h4" id="modalOrderLabel">{{ $t("ecommerceSales.view") }} - ID {{ modal.id }}</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
               </div>
               <div class="modal-body">
                  <div class="card mb-1">
                     <div class="card-body p-3">
                        <div class="row m-0">
                           <div class="col-sm-6 px-1 m-b-10">
                              <label class="mb-1"><i class="fab fa-discord font-12 color-theme mr-1"></i> {{ $t('ecommerceSales.clientDiscord') }}</label>
                              <input type="text" class="form-control" :value="modal.nomeCompleto" disabled />
                           </div>
                           <div class="col-sm-6 px-1 m-b-10">
                              <label class="mb-1"><i class="far fa-sync font-12 color-theme mr-1"></i> Status</label>
                              <input type="text" class="form-control" :value="modal.status" disabled />
                           </div>
                           <div class="col-sm-6 px-1 m-b-10">
                              <label class="mb-1"><i class="far fa-calendar font-12 color-theme mr-1"></i> {{ $t('ecommerceSales.date') }}</label>
                              <input type="text" class="form-control" :value="modal.data == null ? $t('ecommerceSales.unknown') : String(modal.data.dayOfMonth).padStart(2, '0') +'/'+ String(modal.data.monthValue).padStart(2, '0') +'/'+ modal.data.year +' '+ String(modal.data.hour).padStart(2, '0') +':'+ String(modal.data.minute).padStart(2, '0')" disabled />
                           </div>
                           <div class="col-sm-6 px-1 m-b-10">
                              <label class="mb-1"><i class="far fa-calendar font-12 color-theme mr-1"></i> {{ $t('ecommerceSales.lastUpdate') }}</label>
                              <input type="text" class="form-control" :value="modal.dataUltMov == null ? $t('ecommerceSales.unknown') : String(modal.dataUltMov.dayOfMonth).padStart(2, '0') +'/'+ String(modal.dataUltMov.monthValue).padStart(2, '0') +'/'+ modal.dataUltMov.year +' '+ String(modal.dataUltMov.hour).padStart(2, '0') +':'+ String(modal.dataUltMov.minute).padStart(2, '0')" disabled />
                           </div>
                           <div class="col-sm-6 px-1 m-b-10">
                              <label class="mb-1"><i class="fal fa-credit-card font-12 color-theme mr-1"></i> {{ $t('ecommerceSales.paymentMethod') }}</label>
                              <input type="text" class="form-control" :value="modal.pagamento" disabled />
                           </div>
                           <div class="col-sm-6 px-1 m-b-10">
                              <label class="mb-1"><i class="far fa-money-bill-wave-alt font-12 color-theme mr-1"></i> {{ $t('ecommerceSales.value') }}</label>
                              <input type="text" class="form-control" :value="modal.valor" disabled />
                           </div>
                        </div>
                     </div>
                  </div>

                  <div class="card mb-1">
                     <div class="card-body p-3">
                        <div class="row m-0">
                           <div class="col-sm-6 px-1 m-b-10">
                              <label class="mb-1"><i class="far fa-shopping-cart font-12 color-theme mr-1"></i> {{ $t('ecommerceSales.products') }}</label>
                           </div>
                           <div class="col-12 px-1">
                              <div class="cards-1">
                                 <product v-for="(product, index) in modal.produtos" :key="index" :product="product" :index="index" />
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="modal-footer">
                  <button type="button" class="btn btn-round btn-secondary" data-dismiss="modal">{{ $t("serverSettings.alterSettings.permission.button") }}</button>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

import $ from 'jquery'
import { mapState } from 'vuex'
import { ptBR } from 'vuejs-datepicker/dist/locale'
import order from '@/components/ecommerce/Order.vue'
import product from '@/components/ecommerce/ProductSale.vue'
import orderStatus from '@/components/ecommerce/OrderStatus.vue'

export default {
   name: 'Ecommerce_Sales',
   data: function () {
      return {
         ptBR: ptBR,
         search: {'idServer': null, 'status': 'Todos', 'initialDate': null, 'finalDate': null, 'clientDiscord': null, 'groupByStatus': false, 'result': []},
         modal: {
            'id': null, 'idCliente': null, 'idDiscord': null, 'nomeCompleto': null, 'idServer': null, 'data': {}, 'dataUltMov': {}, 'status': null, 
            'pagamento': null, 'urlPagamento': null, 'valor': 0, 'produtos': []
         }
      }
   },
   computed: {
      ... mapState({
         serverS: state => state.serverS,
         urlRest: state => state.urlRest
      })
   },
   watch: {
      'search.groupByStatus' : function () {
         this.search.result = []
         this.search.status = 'Todos'
      }
   },
   components: {
      order, orderStatus, product
   },
   methods: {
      searchSales : function () {
         this.$store.dispatch('alternarTelaCarregamento', true)
         this.search.idServer = this.serverS.id_s;

         this.$axios({
            method: 'post',
            url: this.urlRest +'servicos/searchEcommerceSales',
            headers: {
					'Content-Type': 'application/json'
            },
				data: this.search

         }).then((response) => {
            this.search.result = response.data;

         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  this.$store.dispatch('deslogar')

                  this.$toast.fire({
                     icon: 'warning',
                     title: this.$t("serverSettings.swal.expired")
                  });
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Error: ' + error.response.status
                  });
               }
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         });
      },
      abrirModal : function (order) {
         this.modal = JSON.parse(JSON.stringify(order))
         $('#modalOrder').modal('show')
      },
      calcularData : function () {
         let dataI = new Date();
         let dataF = new Date();
         dataI.setDate(dataI.getDate() - 7);

         if (window.innerWidth < 767) {
            let zeroFill = n => {
               return ('0' + n).slice(-2);
            }

            this.search.initialDate = dataI.getFullYear() +'-'+ zeroFill((dataI.getMonth() + 1)) +'-'+ zeroFill(dataI.getDate());
            this.search.finalDate = dataF.getFullYear() +'-'+ zeroFill((dataF.getMonth() + 1)) +'-'+ zeroFill(dataF.getDate());

         } else {
            this.search.initialDate = dataI;
            this.search.finalDate = dataF;
         }
      }
   },
   mounted() {
      this.calcularData()
      $(window).resize(() => this.calcularData());

      $('#search-nav .dropdown-menu').on('click', function(e) {
         if (e.target.nodeName != 'BUTTON') {
            e.stopPropagation();
         }
      });
   }
}

</script>